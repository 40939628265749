<template>
  <v-app>
    <v-app-bar style="background-color: rgb(20, 158, 236); height: 80px">
      <div
        style="
          padding: 30px;
          margin-top: 10px;
          font-size: 27px;
          font-weight: bold;
          font-family: notosanslight;
          color: white;
        "
      >
        Argong Status
      </div>
    </v-app-bar>
    <v-main>
      <!-- <v-row justify="center">
        <div style="margin-top: 150px">
          <p
            style="
              font-size: 40px;
              font-weight: bold;
              font-family: notosanslight;
            "
          >
            ARGONG STATUS
          </p>
        </div>
      </v-row> -->
      <v-row justify="center">
        <table
          style="
            font-family: notosanslight;
            font-weight: bold;
            font-size: 20px;
            border-spacing: 0;
            border: 1px solid black;
            margin-top: 150px;
          "
        >
          <tr>
            <td id="tabletd">
              <div>
                <div>
                  <div>Users Service</div>
                  <div
                    v-if="users_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="users_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">유저 정보를 관리하는 서버입니다.</p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ users_avail }}%</p>
                </div>
              </div>
            </td>
            <td id="tabletd">
              <div>
                <div>
                  <div>Games Service</div>
                  <div
                    v-if="games_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="games_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">게임 기능을 관리하는 서버입니다.</p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ games_avail }}%</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td id="tabletd">
              <div>
                <div>
                  <div>Tasks Service</div>
                  <div
                    v-if="tasks_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="tasks_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    미션 및 퀘스트들을 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ tasks_avail }}%</p>
                </div>
              </div>
            </td>
            <td id="tabletd">
              <div>
                <div>
                  <div>Chatbot Service</div>
                  <div
                    v-if="talk_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="talk_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    챗봇 서비스를 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ talk_avail }}%</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td id="tabletd">
              <div>
                <div>
                  <div>Studies Service</div>
                  <div
                    v-if="studies_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="studies_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    유저 학습 정보를 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ studies_avail }}%</p>
                </div>
              </div>
            </td>
            <td id="tabletd">
              <div>
                <div>
                  <div>Gamezone Service</div>
                  <div
                    v-if="gamezone_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="gamezone_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    게임존 서비스를 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ gamezone_avail }}%</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td id="tabletd">
              <div>
                <div>
                  <div>Donations Service</div>
                  <div
                    v-if="donations_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="donations_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    기부 컨텐츠를 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ donations_avail }}%</p>
                </div>
              </div>
            </td>
            <td id="tabletd">
              <div>
                <div>
                  <div>LMS Service</div>
                  <div
                    v-if="lms_status == true"
                    id="check"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                  <div
                    v-if="lms_status == false"
                    id="nocheck"
                    style="
                      float: right;
                      top: 0;
                      margin-top: -40px;
                      margin-right: -10px;
                    "
                  ></div>
                </div>
                <div>
                  <p id="serverdescription">
                    학습 지원 웹서비스를 관리하는 서버입니다.
                  </p>
                </div>
                <div id="statusdescription">
                  <p>현재 가용률: {{ lms_avail }}%</p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
//import axios from "axios";

export default {
  name: "App",

  async created() {
    const arr = [
      "users",
      "games",
      "tasks",
      "talk",
      "studies",
      "gamezone",
      "donations",
      "lms",
    ];
    // for (let i = 0; i < arr.length; i++) {
    //   const url = `https://api.argong.io/server/status?name=${arr[i]}`;
    //   const res = await axios.get(url).catch((err) => {
    //     console.log("error");
    //   });

    //   this.dataset.push(res.data.data);
    // }

    this.users_avail = 99.98;
    this.users_status = true;

    this.games_avail = 99.97;
    this.games_status = true;

    this.tasks_avail = 99.98;
    this.tasks_status = true;

    this.talk_avail = 100;
    this.talk_status = true;

    this.studies_avail = 99.97;
    this.studies_status = true;

    this.gamezone_avail = 100;
    this.gamezone_status = true;

    this.donations_avail = 100;
    this.donations_status = true;

    this.lms_avail = 99.98;
    this.lms_status = true;
  },

  data: () => ({
    data: {},
    dataset: [],
    users_avail: 0,
    users_status: true,
    games_avail: 0,
    games_status: true,
    tasks_avail: 0,
    tasks_status: true,
    talk_avail: 0,
    talk_status: true,
    studies_avail: 0,
    studies_status: true,
    gamezone_avail: 0,
    gamezone_status: true,
    donations_avail: 0,
    donations_status: true,
    lms_avail: 0,
    lms_status: true,
  }),
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("./assets/font/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: "notosansmedium";
  src: url("./assets/font/NotoSansKR-Medium.otf");
}

@font-face {
  font-family: "notosanslight";
  src: url("./assets/font/NotoSansKR-Light.otf");
}

#tabletd {
  padding: 20px;
  border: 1px solid black;
  width: 270px;
}

#serverdescription {
  font-size: 12px;
  color: rgb(130, 130, 130);
}

#statusdescription {
  font-size: 16px;
  margin-top: 20px;
}

#check {
  background-image: url("./assets/statuscheck.png");
  background-size: cover;
  width: 30px;
  height: 30px;
}

#nocheck {
  background-image: url("./assets/statusnocheck.png");
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>
